import Head from 'next/head'
import React from 'react'
import Layout from 'src/components/layout/layout'
import Services from 'src/components/services/services'
import { useSiteTitle, useTranslations } from '@dustin-web/microsite-components/src/hooks'
import { withMicrositeWrapper } from '@dustin-web/microsite-nextjs/src/with-server-data/with-microsite-wrapper'
import { withMicrositeProps } from '@dustin-web/microsite-nextjs/src/with-server-data/with-microsite-props'

const ServicesPage = () => {
  const { translate } = useTranslations()
  const { getSiteTitle } = useSiteTitle()
  return (
    <>
      <Head>
        <title>{getSiteTitle(translate('Services_Heading'))}</title>
      </Head>
      <Layout>
        <Services />
      </Layout>
    </>
  )
}

export const getServerSideProps = withMicrositeProps(async () => {
  return { props: {} }
})

export default withMicrositeWrapper(ServicesPage, { showHeader: true })
