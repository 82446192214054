import Button from '@dustin-web/microsite-components/src/button'
import {
  useSiteContext,
  useTranslations,
  useWebToggles,
} from '@dustin-web/microsite-components/src/hooks'
import Image from '@dustin-web/microsite-components/src/image'
import { getSourcingCompany } from '@dustin-web/microsite-config/src/site-context'
import classNames from 'classnames'
import { useState } from 'react'
import { links } from 'src/constants/links'
import Title from '../title'

const Services = ({ showTitle = true }) => {
  const { isToggleEnabled } = useWebToggles()
  const { translate } = useTranslations()
  const { site } = useSiteContext()
  const [isSkyportalToggled] = useState<boolean>(isToggleEnabled('show-myaccount-skyportal-banner'))
  const skyportalImg =
    'https://dustinweb.azureedge.net/content/m4ohjt23/large-working-from-home-laptop_43.jpg'
  const servicesImg = 'https://dustinweb.azureedge.net/content/zoxca3py/large-1282126590_43.jpg'
  const sourcingCompany = getSourcingCompany(site)

  const contentData = [
    {
      theme: 't-gray',
      buttonTheme: 't-dark-gray',
      header: 'Skyportal_Heading',
      context: 'Skyportal_Context',
      buttonText: 'Skyportal_ButtonText',
      buttonLink: links.skyPortal,
      toggle: isSkyportalToggled,
      imageLink: skyportalImg,
      alt: 'Skyportal image',
    },
    {
      theme: 't-light-brown',
      buttonTheme: 't-dark-brown',
      header: 'Services_Marketplace_Heading',
      context: 'Services_Marketplace_Context',
      buttonText: 'Services_Marketplace_ButtonText',
      buttonLink: `${links.serviceMarketPlace}.${sourcingCompany.toLowerCase()}`,
      toggle: true,
      imageLink: servicesImg,
      alt: 'Services marketplace image',
    },
  ]

  return (
    <div className="tw-grid tw-grid-cols-12">
      {showTitle === true && (
        <Title className="tw-col-span-6">{translate('Services_Heading')}</Title>
      )}

      <div className="tw-col-span-7 tw-col-start-1 tw-mb-12 tw-p-0">
        <h3>{translate('Services_SubHeading')}</h3>
      </div>

      <div
        className={classNames(
          'tw-col-span-12 tw-col-start-1 tw-grid tw-grid-cols-12',
          'tw-items-stretch tw-justify-items-stretch tw-gap-4'
        )}>
        {contentData.map(
          (content, i) =>
            !!content.toggle && (
              <article
                key={i}
                className={classNames(
                  'tw-flex tw-flex-col tw-justify-between tw-overflow-hidden',
                  'tw-rounded md:tw-col-span-6 lg:tw-col-span-4',
                  content.theme
                )}>
                <div>
                  <div className="tw-mb-[-8px]">
                    <Image
                      objectFit="cover"
                      layout="responsive"
                      src={content.imageLink}
                      width={820}
                      height={615}
                      alt={content.alt}
                    />
                  </div>

                  <div className={`c-box ${content.theme} tw-mb-2`}>
                    <h1>{translate(content.header)}</h1>
                    <h4>{translate(content.context)}</h4>
                  </div>
                </div>

                <div className="tw-px-4 tw-pb-4">
                  <Button
                    className="tw-mb-0"
                    id={content.header}
                    text={translate(content.buttonText)}
                    icon="arrow-right"
                    primary
                    link
                    block
                    href={translate(content.buttonLink)}
                  />
                </div>
              </article>
            )
        )}
      </div>
    </div>
  )
}

export default Services
