import classnames from 'classnames'
import React from 'react'

type Props = {
  children: React.ReactNode
  className?: string
  spaceBottom?: number
}

const Title = ({ children, className, spaceBottom = 24 }: Props) => {
  return (
    <h3 className={classnames('h2 d-block u-m-0', className, `u-pb-${spaceBottom}`)}>{children}</h3>
  )
}

export default Title
